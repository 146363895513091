<template>
  <div class="align">
    <loader v-if="loadingDeals"></loader>
    <div class="contentBlock" v-if="!loadingDeals  &&
    activeDealData('DealInfo').StateDeal === 0">
      <div class="title">
        <h3>График платежей</h3>
      </div>

      <div class="body">
        <ul>
          <li id="headTable" v-if="viewType == 'singlPage'">
            <div>Дата платежа</div>
            <div>Еженедельный платеж</div>
            <div>Основной долг</div>
            <div>Начисленные проценты</div>
            <div>Остаток основного долга</div>
            <div></div>
          </li>
          <payItem class="cntBgBoxWrap"
                   v-for="(item,index) in PayGraphic"
                   :item="item"
                   :isLast="index == last"
                   :todayDate = "today"
                   @lastitem="lastItemt"
                   :viewType="viewType"
                   :key="index"
          ></payItem>

        </ul>
      </div>

    </div>
    <div v-if="!loadingDeals  &&
    activeDealData('DealInfo').StateDeal !== 0">
      <div class="layoutBorder">
        <div class="layoutHeader">
          <h4>{{demandStatusToTxt(activeDealData('DealInfo').StateDeal)}}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters,mapState} from 'vuex';
  export default {
    name: "PayGraphic",
    data(){
      return {
        status:'loading',
        errorStr:'',
        today: '',
      }
    },
    props:{
      viewType:{
        type:String,
        default:'singlPage',
      },
    },
    methods: {
      demandStatusToTxt(status){
        let out = '';
        switch (status) {
          case 0:
            out = 'Договор активный';
            break;
          case 1:
            out = 'Договор успешно закрыт';
            break;
          case 2:
            out = 'Договор на стадии оформления';
            break;
          default:
            out = 'Нет статуса';
        }
        return out;
      },
      lastItemt(asd){
        let lastItem = document.getElementsByClassName('paid').length - 1;
        if (lastItem <= 0) return false;
        let scrTo = document.getElementsByClassName('paid')[lastItem].offsetTop;
        if (scrTo != undefined ) {
          if (window.outerWidth < 760){
            window.scrollTo(0, scrTo - 100);
          }
        }
      },
      curDate(date) {
        return date > this.today
      },

      scrollToNextPay(){

      },
    },
    mounted(){
      this.$parent.selectedSection = 0
      this.$myApi.checkVersion();
      let t = new Date();
      let month = ((t.getMonth() + 1) < 10) ? '0' + (t.getMonth() + 1): (t.getMonth() + 1);
      let day = (t.getDate() < 10) ? '0' + t.getDate(): t.getDate();
      this.today =t.getFullYear() + '-' + month + '-' + day;
      // this.today ='2020-07-08';
    },
    components: {
      loader: () => import('@/components/other/loader'),
      payItem: () => import('@/components/PayGraphic/payItem'),
    },
    computed: {
      ...mapState({
        loadingDeals:state=>state.contentData.deals.isLoading
      }),
      ...mapGetters([
        'activeDealData',
      ]),
      PayGraphic(){
        return this.activeDealData('PayGraphic')
      },
      last(){
        return Object.keys(this.PayGraphic).length-1;
      }
    },
  }
</script>

<style scoped>
ul{overflow: hidden}
#headTable{display: none}
  @media screen and (min-width: 760px) {
    ul{overflow: visible}
    #headTable{display: flex;justify-content: flex-start;height: 5vw;font-size: 1.4vw;text-align: center;align-items: center}
    #headTable > div{height: 100%;border-right: 1px solid #DFE0E1}
    #headTable > div:nth-child(1){width: 15vw}
    #headTable > div:nth-child(2){width: 15vw}
    #headTable > div:nth-child(3){width: 15vw}
    #headTable > div:nth-child(4){width: 15vw}
    #headTable > div:nth-child(5){width: 15vw}
    #headTable > div:nth-child(6){ border-right: 0}
    /*#headTable > div:nth-child(7){width: 13vw}*/
  }
@media screen and (min-width: 1200px){
  #headTable{height: 50px;font-size: 15px;}


  #headTable > div:nth-child(1){width: 176px;}
  #headTable > div:nth-child(2){width: 176px;}
  #headTable > div:nth-child(3){width: 176px;}
  #headTable > div:nth-child(4){width: 176px;}
  #headTable > div:nth-child(5){width: 176px;}
  #headTable > div:nth-child(6){  }
  /*#headTable > div:nth-child(7){width: 150px;}*/
  .layoutBody{overflow: hidden}
}
</style>
